/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

if (typeof window.setImmediate === "undefined") {
  window.setImmediate = (fn) => {
    setTimeout(fn, 0)
  }
}