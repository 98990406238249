exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-home-js": () => import("./../../../src/pages/blogHome.js" /* webpackChunkName: "component---src-pages-blog-home-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-business-support-js": () => import("./../../../src/pages/business-support.js" /* webpackChunkName: "component---src-pages-business-support-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-download-sparkle-js": () => import("./../../../src/pages/download-sparkle.js" /* webpackChunkName: "component---src-pages-download-sparkle-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-features-breakdown-js": () => import("./../../../src/pages/features/breakdown.js" /* webpackChunkName: "component---src-pages-features-breakdown-js" */),
  "component---src-pages-features-indy-js": () => import("./../../../src/pages/features/indy.js" /* webpackChunkName: "component---src-pages-features-indy-js" */),
  "component---src-pages-features-invoice-and-inventory-js": () => import("./../../../src/pages/features/invoice-and-inventory.js" /* webpackChunkName: "component---src-pages-features-invoice-and-inventory-js" */),
  "component---src-pages-features-payment-js": () => import("./../../../src/pages/features/payment.js" /* webpackChunkName: "component---src-pages-features-payment-js" */),
  "component---src-pages-features-payroll-js": () => import("./../../../src/pages/features/payroll.js" /* webpackChunkName: "component---src-pages-features-payroll-js" */),
  "component---src-pages-features-sparkle-cards-js": () => import("./../../../src/pages/features/sparkle-cards.js" /* webpackChunkName: "component---src-pages-features-sparkle-cards-js" */),
  "component---src-pages-features-sparkle-loan-js": () => import("./../../../src/pages/features/sparkle-loan.js" /* webpackChunkName: "component---src-pages-features-sparkle-loan-js" */),
  "component---src-pages-features-sparkle-loyalty-js": () => import("./../../../src/pages/features/sparkle-loyalty.js" /* webpackChunkName: "component---src-pages-features-sparkle-loyalty-js" */),
  "component---src-pages-features-sparklepay-js": () => import("./../../../src/pages/features/sparklepay.js" /* webpackChunkName: "component---src-pages-features-sparklepay-js" */),
  "component---src-pages-features-split-payment-js": () => import("./../../../src/pages/features/split-payment.js" /* webpackChunkName: "component---src-pages-features-split-payment-js" */),
  "component---src-pages-features-stash-js": () => import("./../../../src/pages/features/stash.js" /* webpackChunkName: "component---src-pages-features-stash-js" */),
  "component---src-pages-features-taxation-js": () => import("./../../../src/pages/features/taxation.js" /* webpackChunkName: "component---src-pages-features-taxation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pacelli-js": () => import("./../../../src/pages/pacelli.js" /* webpackChunkName: "component---src-pages-pacelli-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-sparkle-business-support-js": () => import("./../../../src/pages/sparkle-business-support.js" /* webpackChunkName: "component---src-pages-sparkle-business-support-js" */),
  "component---src-pages-sparkle-events-js": () => import("./../../../src/pages/sparkle-events.js" /* webpackChunkName: "component---src-pages-sparkle-events-js" */),
  "component---src-pages-sparkle-jobs-js": () => import("./../../../src/pages/sparkle-jobs.js" /* webpackChunkName: "component---src-pages-sparkle-jobs-js" */),
  "component---src-pages-stash-a-ton-js": () => import("./../../../src/pages/stash-a-ton.js" /* webpackChunkName: "component---src-pages-stash-a-ton-js" */),
  "component---src-pages-waitlist-success-js": () => import("./../../../src/pages/waitlist-success.js" /* webpackChunkName: "component---src-pages-waitlist-success-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

